import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Zoom from '@mui/material/Zoom'
import './SpellTags.css'

function SpellTags({ tags }: { tags: any[] }) {
  return (
    <ul className="tags">
      {tags.map((tag) => (
        <Tooltip
          key={tag[0]}
          title={
            <React.Fragment>
              <Typography color="inherit">{tag[0]}</Typography>
              {'More Info About this Flag?'}
            </React.Fragment>
          }
          placement="top"
          arrow
          TransitionComponent={Zoom}
        >
          <li className={`tag tag_${tag[1]}`}>{tag[0]}</li>
        </Tooltip>
      ))}
    </ul>
  )
}

export default SpellTags
