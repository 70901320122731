import React, { useState, useEffect } from 'react'
import MainMenu from '../../components/MainMenu/MainMenu'
import BossMenu from '../../components/BossMenu/BossMenu'
import BossJournal from '../../components/BossJournal/BossJournal'
import './Journal.css'

function Journal() {
  const [activeBuild, setActiveBuild] = useState<string>('')

  useEffect(() => {
    const storedActiveBuild = localStorage.getItem('activeBuild')
    const host = process.env.REACT_APP_API_URL

    fetch(host + 'builds')
      .then((response) => response.json())
      .then((data) => {
        const activeBuildExists: boolean = data.some(
          (build: { buildguid: string }) =>
            build.buildguid === storedActiveBuild
        )
        if (activeBuildExists) {
          setActiveBuild(storedActiveBuild || '') // Provide a default value for storedActiveBuild
        } else {
          setActiveBuild(data[0].buildguid)
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])

  useEffect(() => {
    localStorage.setItem('activeBuild', JSON.stringify(activeBuild))
  }, [activeBuild])

  const [compareBuild, setCompareBuild] = useState('')
  useEffect(() => {
    // console.log(compareBuild)
  }, [compareBuild])

  let storedActiveDungeon = 1273 // 1273 = Nerub-ar
  const itemActiveDungeon = localStorage.getItem('activeDungeon')
  if (itemActiveDungeon) {
    try {
      storedActiveDungeon = JSON.parse(itemActiveDungeon)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  const [activeDungeon, setActiveDungeon] = useState(storedActiveDungeon)
  useEffect(() => {
    localStorage.setItem('activeDungeon', JSON.stringify(activeDungeon))
  }, [activeDungeon])

  let storedBossId = 2607 // Ulgrax the Devourer
  const itemBossId = localStorage.getItem('bossId')
  if (itemBossId) {
    try {
      storedBossId = JSON.parse(itemBossId)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  const [bossId, setBossId] = useState(storedBossId)
  useEffect(() => {
    localStorage.setItem('bossId', JSON.stringify(bossId))
  }, [bossId])

  let storedDifficulty = 16
  const itemDifficulty = localStorage.getItem('difficulty')
  if (itemDifficulty) {
    try {
      storedDifficulty = JSON.parse(itemDifficulty)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  let storedDifficulties = [14, 15, 16, 17]
  const itemDifficulties = localStorage.getItem('difficulties')
  if (itemDifficulties) {
    try {
      storedDifficulties = JSON.parse(itemDifficulties)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  const [difficulties, setDifficulties] = useState(storedDifficulties)
  const [difficulty, setDifficulty] = useState(storedDifficulty)

  return (
    <div id="wrapper">
      <MainMenu
        setActiveBuild={setActiveBuild}
        activeBuild={activeBuild}
        setCompareBuild={setCompareBuild}
        activeDungeon={activeDungeon}
        setActiveDungeon={setActiveDungeon}
        difficulties={difficulties}
        setDifficulty={setDifficulty}
        difficulty={difficulty}
      />
      {activeBuild !== '' && (
        <BossMenu
          activeBuild={activeBuild}
          activeDungeon={activeDungeon}
          setBossId={setBossId}
          bossId={bossId}
          setDifficulties={setDifficulties}
        />
      )}
      {activeBuild !== '' && (
        <BossJournal
          activeBuild={activeBuild}
          compareBuild={compareBuild}
          bossId={bossId}
          difficulty={difficulty}
        />
      )}
    </div>
  )
}

export default Journal
