import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import Journal from './Journal/Journal'
import { PrivateAuras } from './PrivateAuras/PrivateAuras'
import { EchoSpeak } from './Guides/EchoSpeak'
import { Symlink } from './Guides/Symlink'
import Login from './Login/Login'
import useToken from '../hooks/useToken'

import { TopMenu } from '../components/TopMenu'
import { Typography, Stack, Link } from '@mui/material'

export default function App() {
  const { token, setToken } = useToken()
  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Journal />} />
        <Route path="/private-auras" element={<PrivateAuras />} />
        <Route path="/echo-speak-guide" element={<EchoSpeak />} />
        <Route path="/symlink" element={<Symlink />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  )
}

function Layout() {
  return (
    <div>
      <TopMenu />
      <Outlet />
    </div>
  )
}

function NoMatch() {
  return (
    <Stack direction="column" spacing={2} justifyContent="top" alignItems="center" sx={{ mt: 2 }}>
      <Typography variant="h1">Nothing to see here!</Typography>
      <Link href="/">Go to the home page</Link>
    </Stack>
  )
}
