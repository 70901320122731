import React, { useState, useEffect } from 'react'

function BossMenu({
  activeBuild,
  activeDungeon,
  setBossId,
  bossId,
  setDifficulties,
}: {
  activeBuild: string
  activeDungeon: number
  setBossId: (id: number) => void
  bossId: number
  setDifficulties: (difficulties: any[]) => void
}) {
  const [bosses, setBosses] = useState([])

  useEffect(() => {
    if (!activeBuild || !activeDungeon) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + activeBuild + '/instance/' + activeDungeon)
      .then((response) => response.json())
      .then((data) => {
        let bosses = data.bosses
        bosses.sort((a: any, b: any) => a.orderindex - b.orderindex)
        setBosses(bosses)

        let difficulties = data.difficulties
        setDifficulties(difficulties)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [activeDungeon, bossId, activeBuild, setDifficulties])

  interface Boss {
    id: string
    name_lang: string
  }

  function BossList(bosses: Boss[]) {
    const clicked = (id: string) => {
      let idToNumber = parseInt(id)
      if (idToNumber === bossId) {
        setBossId(0)
      } else {
        setBossId(idToNumber)
      }
    }
    return bosses.map((boss) => (
      <button
        key={boss.id}
        onClick={() => clicked(boss.id)}
        className={`bossButtons ${parseInt(boss.id) === bossId ? 'active' : ''}`}
      >
        {boss.name_lang}
      </button>
    ))
  }

  return <div id="bosses">{Array.isArray(bosses) && BossList(bosses)}</div>
}

export default BossMenu
