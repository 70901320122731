import React, { useState, useEffect } from 'react'
import { Container, Typography, Backdrop, CircularProgress, Box } from '@mui/material'
import { PrivateAurasTable } from './PrivateAurasTable'

type Error = {
  text?: string
  code?: number
}

type PrivateAurasType = Array<{
  id: number
  name_lang: string
}>

export function PrivateAuras() {
  const [privateAuras, setPrivateAuras] = useState<PrivateAurasType>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error>({})

  useEffect(() => {
    const host = process.env.REACT_APP_API_URL

    fetch(host + 'private-auras')
      .then((response) => {
        if (!response.ok) {
          setError({ text: response.statusText, code: response.status })
          setLoading(false)
          throw new Error('Response not OK')
        }
        return response.json()
      })
      .then((data) => {
        setLoading(false)
        setPrivateAuras(data)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])

  if (error.code) {
    return (
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'top',
          height: '100vh',
          mt: 2,
        }}
      >
        <Box>
          <Typography align="center">There was an error loading the private auras:</Typography>
          <Typography align="center">
            {error.code} {error.text}
          </Typography>
        </Box>
      </Container>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ position: 'relative', minHeight: '300px' }}>
      {!loading && (
        <>
          <Typography variant="h1" align="center" sx={{ m: 2 }}>
            Private Auras
          </Typography>
          <Box display="flex" justifyContent="center">
            <PrivateAurasTable privateAuras={privateAuras} />
          </Box>
        </>
      )}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          top: '50%',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0)',
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}
