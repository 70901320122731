import React, { useState, useEffect } from 'react'
import Builds from '../Builds/Builds'
import ExpansionDropdown from '../Dropdowns/ExpansionDropdown'
import InstanceTypeDropdown from '../Dropdowns/InstanceTypeDropdown'
import InstanceDropdown from '../Dropdowns/InstanceDropdown'
import DifficultyDropdown from '../Dropdowns/DifficultyDropdown'

function MainMenu({
  setActiveBuild,
  activeBuild,
  setCompareBuild,
  activeDungeon,
  setActiveDungeon,
  difficulties,
  setDifficulty,
  difficulty,
}: {
  setActiveBuild: (build: string) => void
  activeBuild: string
  setCompareBuild: (build: string) => void
  activeDungeon: number
  setActiveDungeon: (dungeon: any) => void
  difficulties: any
  setDifficulty: (difficulty: any) => void
  difficulty: number
}) {
  let storedActiveExpansion = 514 // TWW
  const itemActiveExpansion = localStorage.getItem('activeExpansion')
  if (itemActiveExpansion) {
    try {
      storedActiveExpansion = JSON.parse(itemActiveExpansion)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  const [activeExpansion, setActiveExpansion] = useState<number>(
    storedActiveExpansion
  )

  useEffect(() => {
    localStorage.setItem('activeExpansion', JSON.stringify(activeExpansion))
  }, [activeExpansion])

  let storedInstanceType = 2 // 2 = raid, 1 = dungeons
  const itemInstanceType = localStorage.getItem('instanceType')
  if (itemInstanceType) {
    try {
      storedInstanceType = JSON.parse(itemInstanceType)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  const [instanceType, setInstanceType] = useState(storedInstanceType)
  useEffect(() => {
    localStorage.setItem('instanceType', JSON.stringify(instanceType))
  }, [instanceType])

  const updateInstanceType = (id: number): void => {
    if (id === instanceType) {
      return
    }
    setInstanceType(id)
    setActiveDungeon([])
  }

  return (
    <div id="mainMenu">
      <Builds
        setActiveBuild={setActiveBuild}
        activeBuild={activeBuild}
        setCompareBuild={setCompareBuild}
      />
      {activeBuild !== '' && (
        <div id="dropdowns">
          <ExpansionDropdown
            activeBuild={activeBuild}
            activeExpansion={activeExpansion}
            setActiveExpansion={setActiveExpansion}
          />
          <InstanceTypeDropdown
            updateInstanceType={updateInstanceType}
            instanceType={instanceType}
          />
          <InstanceDropdown
            activeBuild={activeBuild}
            activeExpansion={activeExpansion}
            instanceType={instanceType}
            activeDungeon={activeDungeon}
            setActiveDungeon={setActiveDungeon}
          />
          <DifficultyDropdown
            difficulties={difficulties}
            setDifficulty={setDifficulty}
            difficulty={difficulty}
          />
        </div>
      )}
    </div>
  )
}

export default MainMenu
