import React from 'react'

type Props = {
  width: number
  height: number
  fill?: string
}

export const Logo = ({ width, height, fill = '#e51039' }: Props) => (
  <svg width={width} height={height} viewBox="0 0 375 375">
    <path
      d="M176.815,6.507c-48.36,-0 -92.314,19.007 -124.77,49.993c64.057,57.958 150.095,56.348 160.441,119.109c12.036,14.213 25.983,24.22 42.278,29.064c2.246,-4.154 1.584,-8.631 -0,-13.209c21.102,16.454 32.581,37.584 36.991,63.412c-5.284,-2.307 -7.927,-1.103 -13.211,-0c-16.865,31.722 -44.608,45.989 -81.911,44.919c-61.528,-89.02 -158.503,-84.813 -179.446,-27.004c30.493,56.946 90.569,95.702 159.628,95.702c99.892,0 180.998,-81.099 180.998,-180.993c-0,-99.894 -81.106,-180.993 -180.998,-180.993"
      style={{ fill: fill, fillRule: 'nonzero' }}
    />
    <path
      d="M186.063,180.894c-15.026,-12.761 -32.641,-21.568 -52.846,-26.423l23.78,15.854c6.885,15.624 17.003,17.294 29.066,10.569"
      style={{ fill: fill, fillRule: 'nonzero' }}
    />
  </svg>
)
