import React from 'react'
import { Menu, MenuItem, MenuButton, MenuRadioGroup } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import '@szhsin/react-menu/dist/theme-dark.css'

function InstanceTypeDropdown({
  updateInstanceType,
  instanceType,
}: {
  updateInstanceType: (value: number) => void
  instanceType: number
}) {
  const instanceTypeString = ['Dungeons', 'Raids']

  return (
    <Menu
      menuButton={
        <MenuButton>{instanceTypeString[instanceType - 1]}</MenuButton>
      }
      arrow={true}
      theming={'dark'}
    >
      <MenuRadioGroup
        value={instanceType}
        onRadioChange={(e) => updateInstanceType(e.value)}
      >
        <MenuItem type="radio" value={2}>
          Raids
        </MenuItem>
        <MenuItem type="radio" value={1}>
          Dungeons
        </MenuItem>
      </MenuRadioGroup>
    </Menu>
  )
}

export default InstanceTypeDropdown
