import * as React from 'react'
import { Button, AppBar, Box, Toolbar, Container, Menu, MenuItem } from '@mui/material'
import { Logo } from '../components/Logo'
import { LiveHelp as LiveHelpIcon } from '@mui/icons-material/'
import { Link } from 'react-router-dom'

export function TopMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar variant="dense">
          <Logo width={30} height={30} fill="#ffffff" />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button href="/" sx={{ color: 'white', display: 'block' }}>
              Journal
            </Button>
            <Button href="/private-auras" sx={{ color: 'white', display: 'block' }}>
              Private Auras
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <LiveHelpIcon />
            </Box>
            {/* <Button href="/echo-speak-guide" sx={{ color: 'white', display: 'block' }}>
              Echo Speak
            </Button> */}
            <Button
              id="guides-button"
              aria-controls={open ? 'guides-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ color: 'white', display: 'block' }}
            >
              Guides
            </Button>
            <Menu
              id="guides-menu"
              aria-labelledby="guides-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link style={{ color: 'white', textDecoration: 'none' }} to="/echo-speak-guide">
                  Echo Speak
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={{ color: 'white', textDecoration: 'none' }} to="/symlink">
                  Symlink
                </Link>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
