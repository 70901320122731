import React from 'react'
import { Container, Grid, Card, Button, Typography, Box, Stack } from '@mui/material'
import { InsertLink as InsertLinkIcon } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: theme.spacing(1),
  minWidth: '300px',
}))

export function Symlink() {
  return (
    <Container maxWidth="lg">
      <Box style={{ textAlign: 'center' }} m={2}>
        <Typography variant="h1">Symlink Guide</Typography>
      </Box>
      <Stack direction="row" gap={2}>
        <Box>
          <Grid container direction="column" gap={1}>
            <Grid item xs>
              <Item>
                <b>START HERE IF YOU CURRENTLY HAVE A FULLY SYMLINKED ACCOUNT</b>
              </Item>
            </Grid>
            <Grid item xs>
              <Item>1. Make a backup of the linked accounts.</Item>
            </Grid>
            <Grid item xs>
              <Item>
                2. Create a new folder for each account and rename the old ones eg. [373832#1] {`->`} [373832#1-OLD].
              </Item>
            </Grid>
            <Grid item xs>
              <Item>3. Give the new folder(s) the name of the account(s): [373832#1].</Item>
            </Grid>
            <Grid item xs>
              <Item>4. Copy what is in the [-OLD] folder into the new folder.</Item>
            </Grid>
            <Grid item xs>
              <Item>5. You can now delete the [-OLD] folder.</Item>
            </Grid>
            <Grid item xs>
              <Item>6. Repeat for all linked accounts</Item>
            </Grid>
          </Grid>
        </Box>
        <Grid container direction="column" gap={2}>
          <Grid item xs>
            <Item style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
              <Typography variant="body2">1. Download and install the Symlink tool to make things easier:</Typography>
              <Button
                variant="outlined"
                startIcon={<InsertLinkIcon />}
                href={`https://schinagl.priv.at/nt/hardlinkshellext/linkshellextension.html#download`}
              >
                Symlink Tool
              </Button>
            </Item>
          </Grid>
          <Grid item xs>
            <Item>2. Backup your WTF and Addons folder (just in case)</Item>
          </Grid>
          <Grid item xs>
            <Item>
              3. Go to your WoW Account folder, likely something like: [C:\Program Files (x86)\World of
              Warcraft\_retail_\WTF\Account]
            </Item>
          </Grid>

          <Grid item xs>
            <Item>5. Open the folder to your secondary account that you want to link with the main account</Item>
          </Grid>
          <Grid item xs>
            <Item>6. Delete the SavedVariables folder</Item>
          </Grid>
          <Grid item xs>
            <Item>
              7. Go to the main account folder and right-click SavedVariables. Select [Pick Link Source] (Press show
              more options if you are using Windows 11+)
            </Item>
          </Grid>
          <Grid item xs>
            <Item>
              8. Go back to the secondary account folder and this time right click and select [Drop As] {`->`} [Symbolic
              Link] (Press show more options if you are using Windows 11+)
            </Item>
          </Grid>
          <Grid item xs>
            <Item>9. Repeat this for every folder and file you want to symlink. Also for every extra account.</Item>
          </Grid>
          <Grid item xs>
            <Item>
              Recommended symlink files:
              <br />
              - SavedVariables
              <br />
              - bindings-cache.wtf
              <br />
              - confdig-cache.wtf
              <br />
              - edit-mode-cache-account.txt
              <br />- chat-frontend-cache.txt
            </Item>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  )
}
