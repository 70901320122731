import React from 'react'
import { useEffect } from 'react'

function CollapseButton({
  setCollapsed,
  collapsed,
  sections,
  hiddenSections,
  toggleHidden,
}: {
  setCollapsed: (collapsed: boolean) => void
  collapsed: boolean
  sections: any[]
  hiddenSections: any
  toggleHidden: any
}) {
  useEffect(() => {}, [collapsed])

  const clicked = () => {
    sections.forEach((section) => {
      if (collapsed === false && hiddenSections[section.id] === false) {
        toggleHidden(section.id)
        setCollapsed(true)
      } else if (collapsed === true && hiddenSections[section.id] === true) {
        toggleHidden(section.id)
      }
    })
  }

  return (
    <button id="CollapseButton" onClick={() => clicked()}>
      {collapsed ? 'Expand' : 'Collapse'}
    </button>
  )
}

export default CollapseButton
