import React, { useState, useEffect } from 'react'
import { Menu, MenuItem, MenuButton, MenuRadioGroup } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import '@szhsin/react-menu/dist/theme-dark.css'

function ExpansionDropdown({
  activeBuild,
  activeExpansion,
  setActiveExpansion,
}: {
  activeBuild: string
  activeExpansion: number
  setActiveExpansion: (value: any) => void
}) {
  const [expansions, setExpansions] = useState({})
  const [activeExpansionName, setActiveExpansionName] = useState('Expansion')

  useEffect(() => {
    if (!activeBuild) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + activeBuild + '/expansion')
      .then((response) => response.json())
      .then((data) => {
        setExpansions(data)
        let expansionName: { name_lang: string } | undefined = data.find(
          (expansion: { id: number }) => expansion.id === activeExpansion
        )
        if (expansionName) {
          setActiveExpansionName(expansionName.name_lang)
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [activeBuild, activeExpansion])

  return (
    <Menu
      menuButton={<MenuButton>{activeExpansionName}</MenuButton>}
      arrow={true}
      theming={'dark'}
    >
      <MenuRadioGroup
        value={activeExpansion}
        onRadioChange={(e) => setActiveExpansion(e.value)}
      >
        {Array.isArray(expansions) &&
          expansions.map((expansion: { id: string; name_lang: string }) => (
            <MenuItem type="radio" key={expansion.id} value={expansion.id}>
              {expansion.name_lang}
            </MenuItem>
          ))}
      </MenuRadioGroup>
    </Menu>
  )
}

export default ExpansionDropdown
